.search-widget-container input:focus {
    border: 0;
    outline: none;
    box-shadow: none;
    height: auto;
    font-size: 20px !important;
}

.search-widget-container {
    background-image: linear-gradient(#ffffff 40%, #021A94);
    padding: 0px;
    border-radius: 12px;
    /* margin-top: -55px; */
    margin-top: -51px;
}

.search-widget-container>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 12px;
    gap: 10px;
    max-width: 100%;
    position: relative;
    /* border: 1px solid #021A91; */
    /* background-image: linear-gradient(red, yellow); */
    /* 
    box-shadow: 0px 2px 20px rgba(2, 26, 148, 1), rgba(2, 26, 148, 0);
    border-image: linear-gradient(to top, rgba(2, 26, 148, 1), rgba(2, 26, 148, 0));
    border-image-slice: 1;
    box-shadow: 0px 2px 20px linear-gradient(rgba(2, 26, 148, 1) 36%, rgba(2, 26, 148, 0) 0 64%); */
}

.input-field-container {
    padding: 24px;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 160px;
    /* width: 193px; */
    flex-shrink: 0;
    width: 256px;
    box-sizing: border-box;
    position: relative;
}

.input-field-container .ig-homepage-search {
    margin-top: 0 !important;
    left: 0 !important;
    z-index: 9;
    top: 111px !important;
}

.input-field-container :global .vms_variantOfautocomplete_container {
    z-index: 9;
    position: static;
}

/* .calender-container>div>div>div>div { */
/* .calender-container :global .ddrXqo>div {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 160px;
    position: relative;
    padding: 24px 48px 24px 48px !important;
    width: 192px;
    box-sizing: border-box;
    flex-shrink: 0;
    border-radius: 0;
} */

.pax-selection-container {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 160px;
    padding: 27px 38px 27px 45px;
    width: 247px;
    flex-shrink: 0;
    box-sizing: border-box;
    position: relative;
}

.search-hotels-btn-container {
    padding: 19px 19px 19px 24px;
}

.search-hotels-btn-container .search-btn {
    margin-right: 0 !important;
    width: 229px !important;
    height: 64px;
    background: none 0% 0% / 200% 100% rgb(0, 27, 148);
    font-size: 16px;
    font-weight: 600;
    background: none 0% 0% / 200% 100% rgb(0, 27, 148);
    border-radius: 8px;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.calender-container :global .vms_DateRangeCalendar_PickerContainer {
    width: 384px;
    box-sizing: border-box;
    position: relative;
}

.calender-container :global .vms_DateRangeCalendar_PickerContainer {
    gap: 0;
}

/* .search-widget-container>div>div:nth-child(2) {
    padding: 0;
} */

.calender-container :global .vms_DateRangeCalendar_InputContainer {
    width: auto;
}

.search-widget-container input::placeholder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.9);
}

.input-field-container>div>div {
    border: 0;
    border-radius: 0;
    z-index: unset;
}

.search-widget-error-container {
    display: flex;
}

.search-widget-location-error {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-left: 20px; */
}

.search-widget-location-error-inner {
    border: 0;
    position: absolute;
    top: 110px;
    /* margin-top: 40px; */
    /* margin-top: 30px; */

    margin-top: 4px;
    font-size: 12px;
}

.search-widget-date-error {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-left: 98px; */
}

.search-widget-date-error-inner {
    position: absolute;
    /* margin-top: 25px; */
    margin-top: 8px;
    margin-left: 20px;

    top: 100%;
    margin-top: 4px;
    font-size: 12px;
}

.search-widget-pax-error {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-left: 125px; */
}

.search-widget-pax-error-inner {
    position: absolute;
    margin-top: 38px;
}

.input-field-container>div>div button {
    /* display: none; */
    display: flex;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #E0E1E3;
    background: #FFF;
    padding: 0px 16px 0px 12px;
}

/* .calender-container {
    display: flex;
    align-items: center;
    gap: 10px;
} */

/* .calender-container .checkin-date>div,
.calender-container .checkout-date>div {
    width: 160px;
    min-width: 160px;
} */

.calender-container .checkin-date>div>div>div,
.calender-container .checkout-date>div>div>div {
    /* width: 120px;
    min-width: 120px; */
    /* position: absolute; */
    /* margin-top: 0; */
}

/* .calender-container .checkout-date {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.calender-container .checkin-date>div>div>div input,
.calender-container .checkout-date>div>div>div input {
    margin-top: 0;
    border: 0;
} */

.search-widget-container input {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.9) !important;
    padding: 0;
    /* height: 48px; */
    height: auto;
    border: 0;
    max-width: 100%;
    /* min-width: 160px; */
    /* width: 192px; */
    width: 100%;
    cursor: pointer;
    margin-top: 0;

    text-overflow: ellipsis;
}

.search-widget-container input::-moz-placeholder {
    color: rgba(0, 0, 0, 0.9) !important;
    opacity: 1;
}




.search-widget-container input:read-only {
    text-overflow: ellipsis;
}

.checkin-date :global .vms_DateRangeCalendar_Input {
    width: 180px;
}

/* .pax-selection-container {
    width: 180px;
    min-width: 180px;
} */

#pax_selection_1 {
    width: 200px;
}

.pax-selection :global .vms_button {
    width: 100%;
    border-radius: 8px;
    border: 0;
    color: #027BFF;
    /* font-family: Inter; */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 45px;
    background: #F4F4F8;
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    letter-spacing: 0.3px;
}

.pax-selection :global .vms_paxselection_mainroomcontainer {
    position: absolute;
    /* width: 335px; */
    width: 402px !important;
    box-sizing: border-box;
    /* height: 352px; */
    border-radius: 8px;
    border: 1px solid #F4F4F4;
    background: #FFF;
    z-index: 9;
    padding: 33px 32px 35px 33px;
    gap: 0;
    top: 108px;
    left: 0;
    box-shadow: 0 0 4px #d5d5d5;
    overflow: auto;
    max-height: 450px;
}

.pax-selection :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar {
    width: 8px;
}

.pax-selection :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    cursor: pointer;
}

.pax-selection :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.pax-selection :global .vms_paxselection_mainroomcontainer .vms_paxselection_chidage_desktopcontainer {
    display: flex;
    flex-wrap: wrap;
    width: unset;
}

.pax-selection :global .vms_paxselection_roomtitle {
    margin: 0;
}

.pax-selection :global .vms_paxselection_roominnercontainer {
    padding: 0;
}

.pax-selection :global .vms_paxselection_addmorebtn_container {
    padding: 0;
    /* border-top: 1px solid #EBEBEB; */
    border: none;
}

.pax-selection :global .vms_paxselection_roomtitle>div:nth-child(2) {
    /* background: transparent; */
    display: none;
}

.pax-selection :global .vms_paxselection_roomtitle>div {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.16px;
    padding-bottom: 20px;
    text-transform: capitalize;
}

.pax-selection :global .vms_paxselection_roomtitle>div>span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.16px;
    padding-bottom: 36px;
}

.pax-selection :global .vms_paxselection_pax_container {
    padding-bottom: 20px;
}

.pax-selection :global .vms_paxselection_pax_title_container span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.16px;
    /* padding-bottom: 35px; */
}

.pax-selection :global .vms_paxselection_pax_title_container {
    color: #999;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* padding-bottom: 35px; */
}

.pax-selection :global .vms_paxselection_paxcontainer {
    padding-bottom: 10px;
}

.pax-selection :global .vms_paxselection_paxtitle span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 125% */
    letter-spacing: 0.16px;
    /* padding-bottom: 32px; */
}

.pax-selection :global .vms_paxselection_paxtitle {
    color: #999;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* padding-bottom: 32px; */
}

.pax-selection :global .vms_paxselection_maininputicon {
    display: none;
}

.pax-selection :global .vms_paxselection_addmorebtn {
    color: #218AFE;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.3px;
    /* padding-top: 20px;   */
}

.pax-selection :global .vms_paxselection_childage_containerdiv {
    border-radius: 5px;
    border: 1px solid #CCC;
    margin-bottom: 20px;
    padding: 11px;
    width: 40%;
}

.pax-selection :global .vms_paxselection_childage_inputlabel {
    position: absolute;
    background: #fff;
    /* margin-left: -4px;
    margin-top: -19px; */
    margin-left: -4px;
    margin-top: -23px;
    padding: 0 5px;
    z-index: +1;
    color: #555;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    /* line-height: 17px; */
    /* 130.769% */
    letter-spacing: -0.08px;
}

.pax-selection :global .vms_paxselection_chidage_desktopcontainer {
    display: inline-block;
}

.pax-selection :global .vms_paxselection_roominner_container {
    padding: 0 0 32px 0;
}

.pax-selection :global .vms_paxselection_roomcontainer {
    border-bottom: 1px solid #EBEBEB;
    /* margin-bottom: 32px; */
    margin-bottom: 20px;
}

.pax-selection :global .vms_stepper_container {
    /* padding-bottom: 32px; */
}

.pax-selection :global .vms_paxselection_pax_header_title {
    color: #222;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    /* 130.769% */
    letter-spacing: -0.08px;
}

.pax-selection :global .vms_paxselection_roombtn_edit {
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    /* 130.769% */
    letter-spacing: -0.08px;
    color: #218AFE;
}

.pax-selection :global .vms_paxselection_roombtn_remove {
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    /* 130.769% */
    letter-spacing: -0.08px;
    color: red;
}

.search-btn {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    width: 220px !important;
    height: 60px !important;
    cursor: pointer;
    margin-right: 19px !important;
}

.search-widget-container label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.5);
    text-transform: unset;
    margin-bottom: 0;

    margin-bottom: 8px;
}

.search-widget-container :global .vms_DateRangeCalendar_OverlayContainer .vms_DateRangeCalendar_PickerContainer .vms_DateRangeCalendar_InputContainer .vms_DateRangeCalendar_IconCotainer>img {
    display: none;
}

.CalendarContainer {
    background-color: black;
}

.IconCotainer {
    display: none;
}

.search-widget-container>div {
    position: relative;
    /* box-shadow: 0px 2px 2px 0px #021A94; */
    border: 1px #021a94 solid;
    justify-content: unset;
    gap: 0;
    margin-top: 16px;
}

.search-widget-container .search-widget-fields {
    margin-top: 28px;
}

.pax-selection {
    /* position: relative; */
}

/* .pax-selection :global .dZhFwn{ */
.pax-selection :global .vms_paxselection_paxmainwrapper>div>div {
    width: 100%;
}

.vms_paxselection_mainroomcontainer {
    overflow: auto;
    height: 446px;
}

.pax-selection>div:nth-child(2) {
    position: absolute;
    /* width: auto; */
    left: -20px;
    top: 79px;
    z-index: 9;
    background-color: #fff;
    /* padding: 10px 15px; */
    box-shadow: 0 0 4px #d5d5d5;
    background: #FFFFFF;
    border: 1px solid #F4F4F4;
    border-radius: 8px;
}

.search-widget-container>div>div>div>div:nth-child(3) {
    position: absolute;
    width: 402px;
    height: 430px;
    left: -18px;
    top: 93px;
    /* overflow-y: auto; */
    background: #fff;
    /* padding: 10px 15px; */
    box-shadow: 0 0 4px #d5d5d5;
    margin-top: 8px;
    border-radius: 8px;

    height: 334px;
    overflow-y: auto;
    padding: 32px 24px;
}

.ig-homepage-search-suggestion {
    height: 430px !important;
}

.search-widget-container .checkout-date>div>div>div:nth-child(2) {
    left: -250px;
}

.search-widget-container>div>div>div>div:nth-child(3)::-webkit-scrollbar {
    width: 5px;
}

/* Track */
.search-widget-container>div>div>div>div:nth-child(3)::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.search-widget-container>div>div>div>div:nth-child(3)::-webkit-scrollbar-thumb {
    background: #001B94;
}



.pax-selection>div:nth-child(2)>div {
    justify-content: space-between;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
}

.pax-selection>div:nth-child(2)>div span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 20px;
    color: #999;
}

.pax-selection>div>div>div svg {
    color: #0b80fe;
}

.pax-selection>div:nth-child(2)>div:nth-child(4) button {
    font-family: 'Roboto' !important;
    background: #FFFFFF;
    border: 1px solid #000;
    border-radius: 8px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    text-transform: capitalize;
    color: #000000;
    margin-top: 15px;
}

.pax-done-btn-container {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #000;
    width: 337px;
    height: 48px;
    font-weight: 700;
    font-family: Roboto;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, .9) !important;
}


.calender-main :global .vms_DateRangeCalendar_itemMainContainer div {
    display: block !important;
}



.search-widget-container>div>div>div>div:nth-child(3) ul li {
    border: 0;
    list-style: none;
    /* padding: 0 0 12px 0; */
}


.jp-no-result-found {
    /* display: grid;
    align-items: center;
    justify-content: center; */
    text-align: center;
}

.jp-no-result-found-text {
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.jp-mobile-recently-searched li {
    /* padding: 0 0 24px 24px;
    border-bottom: none; */
    width: 100%;
}

.homepage-search-location-title {
    margin-left: 8px;
    color: #222;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    /* padding-bottom: 2px; */
    cursor: pointer;
}

.homepage-search-location-subtitle {
    color: rgba(0, 0, 0, 0.50);
    /* text-align: right; */
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    padding-left: 8px;
    cursor: pointer;
}

.homepage-search-location {
    display: flex;
    /* padding-right: 8px; */
}

.desktop-li-recent-desktop {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
}

.selectedoption {
    border: 0 !important;
    /* padding: 0 0 0 8px !important; */
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
}

.selectedoption-subtitle {
    color: rgba(0, 0, 0, 0.50);
    /* text-align: right; */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    /* margin-left: 9px; */
}

.calender-container :global .vms_DateRangeCalendar_CalendarContainer {
    position: absolute;
    /* width: 718px; */
    /* top: 126px; */
    left: -156px;
    z-index: 9;
    background-color: #fff;
    box-shadow: 0 0 4px #d5d5d5;
    background: #fff;
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    overflow: hidden;
    width: 722px;
    box-sizing: border-box;
    top: 111px;
    height: 380px;
}

.calender-container :global .vms_DateRangeCalendar_SelectDateButton {
    color: #333;
    text-align: center;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    /* padding-bottom: 23px; */
    padding: 0;
}

.checkin-date :global .vms_DateRangeCalendar_NextIconContainer {
    cursor: pointer;
}

.checkout-date :global .vms_DateRangeCalendar_NextIconContainer {
    cursor: pointer;
}

.search-widget-container>div>div>div>div:nth-child(3) ul>div {
    /* display: flex; */
    flex-wrap: wrap;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
}

.im-autosuggested .location-suggestion-icon-wrapper {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    display: grid;
    place-items: center;
}

.im-autosuggested .location-suggestion-icon-wrapper img {
    width: auto;
}

/* .search-widget-container>div>div>div>div:nth-child(3) ul li>div img {
    width: 24px;
    height: 24px;
} */

.calender-container :global .vms_DateRangeCalendar_dateItem {
    font-weight: 600;
    /* color: #333333; */
}

/* .calender-container :global .vms_DateRangeCalendar_WeekdayCol {
    color: #027BFF;
    font-weight: 600 !important;
    padding-bottom: 6px;
    font-size: 15px;
} */

.calender-container :global .vms_DateRangeCalendar_dateItem,
.main-check-in :global .vms_DateRangeCalendar_dateItem {
    /* color: #027BFF; */
    font-weight: 600 !important;
    /* padding-bottom: 6px; */
}

.pax-selection :global .vms_paxselection_childagedropdown {
    z-index: 9;
}

@media screen and (min-width:768px) and (max-width:1270px) {

    /* .main-input-going {
        padding: 23px 40px;
    } */
    .main-guest-select-container {
        margin-right: 16px;
    }

    .did-floating-label-content {
        margin-right: 16px;
    }

    .main-input-going .main-check-in {
        margin-right: 16px;
    }

    .did-floating-label-content :global .vms_variantOfautocomplete_searchinput {
        max-width: 308px;
        width: 100% !important;
    }

    .main-check-in :global .vms_inputtext_inputcontainer input {
        max-width: 308px;
        width: 100% !important;
    }

    .main-guest-select-container :global .dZhFwn {
        max-width: 305px !important;
        width: 100% !important;
    }

    .main-check-in :global .vms_DateRangeCalendar_OverlayContainer {
        max-width: 308px !important;
        width: 100% !important;
    }

    .main-guest-select :global .dZhFwn {
        width: 100%;
    }

    .main-guest-select input {
        max-width: 308px;
        width: 100%;
        font-size: 16px;
    }

}

@media (min-width: 1024px) and (max-width: 1365px) {
    /* .search-widget-container {
        padding: 0 30px;
    } */

    .search-btn {
        width: 100%;
    }

    /* .search-widget-container input {
        font-size: 14px;
    }

    .search-widget-container input:focus {
        font-size: 14px !important;
    }

    .search-widget-container input::placeholder {
        font-size: 14px;
    } */

    .calender-container {
        gap: 0;
    }

    .search-widget-container input {
        /* width: 150px; */
        /* min-width: 150px; */
    }

    .pax-selection :global .vms_paxselection_mainroomcontainer {
        top: 114px;
    }

    .calender-container :global .vms_DateRangeCalendar_CalendarContainer {
        top: 116px;
    }

    .input-field-container .ig-homepage-search {
        top: 116px !important;
    }

    .pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_paxmain input {
        width: unset;
        min-width: unset;
    }

    /* .search-widget-container>div>div {
        min-width: 120px;
    } */

    .pax-selection-container {
        /* width: 130px; */
    }

    .search-widget-container label {
        line-height: 29px;
        height: 25px;
    }
}

.main-input-going {
    display: flex;
    justify-content: space-between;
    /* align-items:flex-end; */
    /* padding: 24px 42px 23px 62px; */
    /* padding: 24px 12px 23px 12px; */
    padding: 24px 54px;

}

.input-going {
    margin-bottom: 27px;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    /* max-width: 100%; */
}

.did-floating-label-content {
    position: relative;
    /* margin-right: 16px; */
}

.did-floating-label-content :global .vms_variantOfautocomplete_container {
    z-index: 5;
}

.did-floating-label-content :global .vms_variantOfautocomplete_searchbutton {
    display: none;
}

.did-floating-label-content :global .vms_variantOfautocomplete_searchbtndiv {
    border: none !important;
    z-index: 5;
}

.did-floating-label-content :global .vms_variantOfautocomplete_searchbtndiv:focus-visible,
.did-floating-label-content :global .vms_variantOfautocomplete_searchbtndiv>div:focus-visible,
.did-floating-label-content :global .vms_variantOfautocomplete_container:focus-visible {
    outline: 0 !important;

}

.did-floating-label-content:focus-visible {
    outline: 0 !important;

}

.did-floating-label-content :global .vms_variantOfautocomplete_searchinput {
    position: relative;
    width: 276px;
    height: 50px;
    margin: 0;
    color: rgba(0, 0, 0, .9);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
    cursor: pointer;
    border-radius: 3px 3px;
    text-overflow: ellipsis;
    border: 1px solid #666 !important;
}

.did-floating-label-content label {
    font-family: 'Roboto' !important;
    position: absolute;
    top: -8px;
    /* left: 10px; */
    margin-left: 13px;
    background: white;
    display: inline-block;
    width: auto;
    padding: 0 3px;
    color: #999999;
    border-color: black !important;
    font-size: 13px;
    /* z-index: 999; */
    z-index: 9;
    font-weight: 400;
}

.srp-main-click {
    /* border: 1px solid; */
    box-shadow: 0 0 4px #d5d5d5;
    background-color: white;
    position: absolute;
    top: 59px;
    width: 402px;
    height: 430px;
    /* width: 400px;
    height: 225px; */
    overflow-y: scroll;
    display: grid;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.srp-main-click::-webkit-scrollbar {
    display: none;
}

.srp-click-container {
    padding-bottom: 32px;
}

/* .srp-click-widget {
    margin-left: 10px;
    margin-top: 10px;
} */

.srp-click-widget :global ul {
    padding-bottom: 20px;
    list-style-type: none;
}

.ig-mobile-suggestion-title {
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 32px 0 26px 24px;
}

.jp-mobile-recently-searched img {
    position: absolute;
}

.jp-mobile-recently-searched>li {
    padding: 0 !important;
    border-bottom: none;
    list-style-type: none;
}

.jp-mobile-recently-searched>li:not(:last-of-type) {
    margin-bottom: 24px;
    list-style: none;
}


.srp-title {
    margin-left: 31px;
    color: rgba(0, 0, 0, 0.70);
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
    cursor: pointer;
}

.srp-sub-title {
    color: rgba(0, 0, 0, 0.50);
    /* text-align: right; */
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-left: 30px;
    cursor: pointer;
}

.suggestions-location-container>li.selectedoption {
    padding: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 2px;
}

.suggestions-location-container>li.selectedoption-subtitle {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.srp-no-result-found {
    text-align: center;
}

.srp-no-result-found-text {
    color: rgba(0, 0, 0, 0.70);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
}

.did-floating-label-content+.location-error-message .search-widget-location-error-inner {
    top: unset;
}

.input-check-in-error-msg-container {
    position: absolute;
}

.input-check-in-error-msg-container .input-check-in-error-msg-text {
    margin-top: 4px;
    font-size: 12px;
    color: red;
    font-family: 'Roboto';
}

.location-error-message {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-bottom: 3px;  */
}

.location-error-message-inner {
    position: absolute;
    padding-top: 3px;
}

.date-error-message {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-bottom: 3px; */
}

.date-error-message-inner {
    position: absolute;
    padding-top: 3px;
}

.pax-error-message {
    color: red;
    font-size: 14px;
    font-family: 'Roboto';
    /* padding-bottom: 3px; */
}

.pax-error-message-inner {
    position: absolute;
    padding-top: 3px;
}

.main-input-going .main-check-in {
    position: relative;
}

.main-check-in :global .vms_inputtext_inputcontainer input {
    position: relative;
    border: 1px solid #666 !important;
    width: 100%;
    height: 50px;
    margin: 0;
    color: rgba(0, 0, 0, .9);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
    cursor: pointer;
    border-radius: 3px 3px;
    text-overflow: ellipsis;
}

.main-check-in :global .vms_DateRangeCalendar_HeaderNavMonth {
    border-bottom: 2px solid #D1D3D4 !important;
}

.main-check-in :global .vms_DateRangeCalendar_CalendarContainer {
    position: absolute;
    /* z-index: 9999; */
    z-index: 9;
    box-shadow: 0 0 4px #d5d5d5;
    border: none;
    top: 59px;
    height: 380px;
}

.main-check-in :global .vms_DateRangeCalendar_OverlayContainer .vms_DateRangeCalendar_PickerContainer{
    width: 305px;
}
.main-check-in :global .vms_DateRangeCalendar_OverlayContainer .vms_inputtext_container{
    width: 305px;
}
.main-check-in :global .selected.start .vms_DateRangeCalendar_SelectedDate:before {
    content: "";
    /* position: absolute;
    top: 0px;
    height: 30px;
    right: -1px;
    width: 20px;
    background-color: #f4f4f8;
    z-index: -1; */
}



/* Ritesh */

.main-guest-select-container {
    position: relative;
    /* margin-right: 16px; */
}

/* .main-guest-select-container :global .dZhFwn {
    width: 305px;
} */

.input-check-in {
    font-family: 'Roboto' !important;
    position: absolute;
    /* top: 48px; */
    top: 105px;
    /* left: 429px; */
    margin-left: 13px;
    background: white;
    display: inline-block;
    width: auto;
    padding: 0 3px;
    color: #999999;
    font-size: 13px;
    z-index: +1;
}

.main-check-in .input-check-in {
    top: -6px;
}

.guests-check-in {
    font-family: 'Roboto' !important;
    position: absolute;
    /* top: 23px; */
    top: -6px;
    margin-left: 13px;
    /* left: 749px; */
    background: white;
    display: inline-block;
    width: auto;
    padding: 0 3px;
    color: #999999;
    font-size: 13px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
}

/* .main-check-in :global .vms_DateRangeCalendar_SelectedDate {
    background: #027BFF !important;
    border-radius: 60px !important;
    width: 30px !important;
    color: #fff !important;
} */

.search-button-container {
    /* margin-left: 6px; */
}

.search-button-container button {
    background-color: #001B94 !important;
    border-radius: 4px !important;
    justify-content: center !important;
    /* align-items: center !important; */
    gap: 8px !important;
    width: 206px !important;
    height: 50px !important;
    color: #FFF;
    text-align: center;
    font-size: 14px;
    font-family: Roboto !important;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.main-guest-select :global .vms_paxselection_mainpaxinput {
    color: rgba(0, 0, 0, .9);
    font-size: 16px;
    font-family: Roboto;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.1px;
    height: 50px;
    width: 300px;
    border: 1px solid #666 !important;
}

.main-guest-select :global .vms_paxselection_childage_input {
    font-family: 'Roboto';
    font-style: normal;
    /* font-weight: 700; */
    font-size: 20px;
    /* line-height: 26px; */
    letter-spacing: 0.1px;
    color: rgba(0, 0, 0, 0.9);
    padding: 0;
    height: 48px;
    border: 0 !important;
    border-radius: 0 !important;
    max-width: 100%;
    /* min-width: 160px; */
    width: 192px;
    cursor: pointer;
    margin-top: 0;
    height: unset;

    font-size: 17px;
    font-weight: 400;
    line-height: normal;
    height: unset;
}

.main-guest-select :global .vms_paxselection_childage_inputicon {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.main-guest-select :global .vms_paxselection_mainpaxinput:focus {
    outline: none;
    font-size: 16px;
}

.main-guest-select :global .vms_paxselection_childage_input:focus {
    outline: none;
}

.main-guest-select :global .vms_paxselection_maininputicon {
    display: none;
}

.main-guest-select :global .vms_paxselection_mainroomcontainer {
    position: absolute;
    top: 59px;
    box-shadow: 0 0 4px #d5d5d5;
    background-color: white;
    border: none;
    border-radius: 8px;
    width: 402px;
    padding: 32px 32px 35px 32px;
    z-index: 9;
    max-height: 450px;
    overflow: auto;
}

.main-guest-select :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar {
    width: 8px;
}

.main-guest-select :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    cursor: pointer;
}

.main-guest-select :global .vms_paxselection_mainroomcontainer::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.main-guest-select :global .vms_paxselection_roomtitle {
    margin: 0;
}

.main-guest-select :global .vms_paxselection_roomtitle>div {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    padding-bottom: 20px;
    text-transform: capitalize;
}

.main-guest-select :global .vms_paxselection_roomtitle>div:nth-child(2) {
    display: none;
}

.main-guest-select :global .vms_paxselection_roomtitle>div>span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    padding-bottom: 36px;
}

.main-guest-select :global .vms_paxselection_roominnercontainer {
    padding: 0;
}

.main-guest-select :global .vms_paxselection_pax_title_container span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    /* padding-bottom: 35px; */
}

.main-guest-select :global .vms_paxselection_pax_title_container {
    color: #999;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* padding-bottom: 35px; */
}

.main-guest-select :global .vms_stepper_text {
    margin-top: 2px;
}

.main-guest-select :global .vms_stepper_container .vms_stepper_plusbutton img,
.main-guest-select :global .vms_stepper_container .vms_stepper_minusbutton img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.main-guest-select :global .vms_paxselection_paxtitle span {
    color: #222;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    /* padding-bottom: 32px; */
}

.main-guest-select :global .vms_paxselection_paxtitle {
    color: #999;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    /* padding-bottom: 32px; */
}

.main-guest-select :global .vms_paxselection_pax_container {
    padding-bottom: 20px;
}

.main-guest-select :global .vms_paxselection_paxcontainer {
    /* padding-bottom: 32px; */
}

.main-guest-select :global .vms_paxselection_roomcontainer {
    border-bottom: 1px solid #EBEBEB;
    /* margin-bottom: 32px; */
    margin-bottom: 20px;
}

.main-guest-select :global .vms_paxselection_addmorebtn_container {
    padding: 0;
    /* border-top: 1px solid #EBEBEB; */
    border: none;
}

.main-guest-select :global .vms_paxselection_pax_header_title {
    color: #222;
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.08px;
}

.main-guest-select :global .vms_paxselection_roombtn_edit {
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.08px;
    color: #218AFE;
}

.main-guest-select :global .vms_paxselection_roombtn_remove {
    font-family: Roboto;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: -0.08px;
    color: red;
}

.main-guest-select :global .vms_paxselection_roominner_container {
    padding: 0 0 32px 0 !important;
}

.main-guest-select>div>div>div svg {
    color: #0b80fe;
}

.main-guest-select :global .vms_paxselection_addmorebtn {
    color: #218AFE;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.3px;
    /* padding-top: 20px;   */
}


.main-guest-select :global .vms_button {
    width: 100%;
    border-radius: 8px;
    border: 0;
    color: #027BFF;
    /* font-family: Inter; */
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 45px;
    background: #F4F4F8;
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 700;
    line-height: 19.36px;
    text-align: center;
    letter-spacing: 0.3px;
}

.main-guest-select :global .vms_paxselection_chidage_desktopcontainer {
    /* display: inline-block; */
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
}

.main-guest-select :global .vms_paxselection_childage_containerdiv {
    border-radius: 5px;
    border: 1px solid #CCC;
    margin-bottom: 20px;
    padding: 11px;
    width: 40%;
}

.main-guest-select :global .vms_paxselection_paxmain {
    width: unset;
}

.main-guest-select :global .vms_paxselection_childage_inputlabel {
    position: absolute;
    background: #fff;
    margin-left: -4px;
    margin-top: -19px;
    padding: 0 5px;
    z-index: +1;
    text-transform: unset;
    color: #555;
    font-family: Roboto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    /* 130.769% */
    letter-spacing: -0.08px;
}

.main-guest-select :global .vms_paxselection_childagedropdown {
    z-index: 9;
    width: auto;
    padding: 23px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    max-height: unset;
}

.main-guest-select :global .vms_paxselection_childagedropdown::-webkit-scrollbar {
    display: none;
}

.main-guest-select :global .vms_paxselection_listitem {
    color: #666;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: flex-start;
}

.guest-select :global .vms_DateRangeCalendar_Input {
    font-family: 'Roboto' !important;
    font-weight: 500 !important;
    margin-left: -30px !important;
}

.test-autocomplete :global .vms_variantOfautocomplete_searchinput {
    margin-left: -30px !important;
    color: rgba(0, 0, 0, 0.90) !important;
    font-size: 16px !important;
    font-family: Roboto !important;
    font-weight: 700 !important;
    line-height: 22px;
    letter-spacing: 0.1px;
    cursor: pointer;
}

.test-autocomplete :global .vms_variantOfautocomplete_searchbtndiv {
    border: 1px solid #666;
    width: 303px;
    height: 48px;
}

.ig-homepage-search {
    position: absolute;
    width: 402px;
    box-sizing: border-box;
    height: 430px;
    left: 0px;
    top: 50px;
    /* overflow-y: auto; */
    background: #fff;
    /* padding: 10px 15px; */
    box-shadow: 0 0 4px #d5d5d5;
    margin-top: 8px;
    border-radius: 8px;
    /* height: 334px; */
    overflow-y: scroll;
    padding: 32px 24px;
}

.ig-title {
    color: #666;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: 0.1px;
    padding-bottom: 26px;
}

.ig-homepage-search-suggestion .ig-title {
    color: rgba(0, 0, 0, .5);
    font-size: 14px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: .1px;
    padding-bottom: 26px;
}

.ig-homepage-search::-webkit-scrollbar {
    display: none;
}

.child-age-checkout-text {
    margin-top: 32px;
}

.child-age-checkout-text h3 {
    color: #222;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0.14px;
    margin-bottom: 4px;
}

.child-age-checkout-text p {
    color: #222;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.14px;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer {
    display: block;
    margin-top: 10px;
    width: 190px;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_paxmain {
    width: 100%;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_childagedropdown {
    width: auto;
    padding: 23px 20px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    /* background: #9c9797; */
    max-height: unset;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_childagedropdown::-webkit-scrollbar {
    display: none;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_listitem {
    color: #666;
    font-family: 'Roboto';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
    text-align: flex-start;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_childage_inputicon {
    top: 50%;
    transform: translateY(-50%);
    right: 0;
}

.pax-selection-container :global .vms_paxselection_chidage_desktopcontainer .vms_paxselection_childage_input {
    /* color: #999; */
    font-family: Roboto;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: unset;
}

.pax-selection :global .vms_stepper_minusbutton img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.pax-selection :global .vms_stepper_plusbutton img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.pax-selection :global .vms_stepper_text {
    margin-top: 3px;
}


.jp-mobile-recently-searched-popular-dest {
    display: flex;
    flex-wrap: wrap;
}

.homepage-search-location-popular-dest {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 24px;
    border: 1px solid #E0E1E3;
    background: #FFF;
    height: 36px;
    padding: 8px 12px;
    color: rgba(0, 0, 0, 0.90);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.jp-mobile-recently-searched-popular-dest li {
    border-bottom: none;
    padding: 0 8px 12px 0;
}

.suggestions-location-container {
    /* margin-left: 18px; */
    /* padding: 0 0 24px 0; */
    width: 100%;
    margin-left: 8px;
}

.homepage-search-location-image img {
    margin-top: -1px;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .input-check-in {
        margin-left: 2px;
    }

    .ig-homesearch-container {
        margin: -30px 16px 0px 16px;
        position: relative;
    }
}




/* new homepage serachwidget css */
.calender-container :global .vms_DateRangeCalendar_PickerBodyContainer,
.main-check-in :global .vms_DateRangeCalendar_PickerBodyContainer {
    width: 100%;
}

.calender-container :global .vms_DateRangeCalendar_HeaderContainer,
.main-check-in :global .vms_DateRangeCalendar_HeaderContainer {
    height: 38px;
}

.calender-container :global table.vms_DateRangeCalendar_dateTableContainer,
.main-check-in :global table.vms_DateRangeCalendar_dateTableContainer {
    width: 323px;
    padding: 10px;
}

.calender-container :global .vms_DateRangeCalendar_WeekdayCol,
.main-check-in :global .vms_DateRangeCalendar_WeekdayCol {
    color: #027BFF;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
}

.calender-container :global .vms_DateRangeCalendar_itemMainContainer,
.main-check-in :global .vms_DateRangeCalendar_itemMainContainer {
    background-color: transparent !important;
    border: 0;
}


.calender-container :global .vms_DateRangeCalendar_dateItem,
.main-check-in :global .vms_DateRangeCalendar_dateItem {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    letter-spacing: .1px;
    line-height: 22px;
    font-weight: 400;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start.vms_DateRangeCalendar_itemMainContainer,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start.vms_DateRangeCalendar_itemMainContainer {
    border-radius: 50px 0 0 50px !important;
}



.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.end.vms_DateRangeCalendar_itemMainContainer,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.end.vms_DateRangeCalendar_itemMainContainer {
    border-radius: 0px 23px 18px 0 !important;
    width: 0 !important;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.end .vms_DateRangeCalendar_SelectedDate::before,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.end .vms_DateRangeCalendar_SelectedDate::before {
    content: "";
    position: absolute;
    top: 0;
    height: 30px;
    left: -8px;
    width: 33px;
    background-color: #e6f2ff;
    z-index: -1;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start .vms_DateRangeCalendar_SelectedDate::before,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start .vms_DateRangeCalendar_SelectedDate::before {
    content: "";
    position: absolute;
    top: 0;
    height: 40px;
    right: -10px;
    width: 33px;
    background-color: #ecf4fd;
    z-index: -1;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.between.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_Date_Container,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.between.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_Date_Container {
    background-color: #e6f2ff;
    height: 30px !important;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.between.vms_DateRangeCalendar_itemMainContainer>div .vms_DateRangeCalendar_dateItem:hover,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.between.vms_DateRangeCalendar_itemMainContainer>div .vms_DateRangeCalendar_dateItem:hover {
    background-color: #ecf4fd;

}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .vms_DateRangeCalendar_itemMainContainer,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .vms_DateRangeCalendar_itemMainContainer {
    border: none;
    background-color: transparent !important;
}

.calender-container :global .vms_DateRangeCalendar_CalendarContainer .vms_DateRangeCalendar_PickerBodyContainer div,
.main-check-in :global .vms_DateRangeCalendar_CalendarContainer .vms_DateRangeCalendar_PickerBodyContainer div {
    font-size: 15px;
    font-style: normal;
    line-height: 22px;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected .vms_DateRangeCalendar_SelectedDate,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected .vms_DateRangeCalendar_SelectedDate {
    font-size: 14px !important;
    background-color: #027bff !important;
    color: #fff !important;
    width: 30px !important;
    height: 30px !important;
    border-radius: 100%;
    line-height: 30px !important;
}

.calender-container :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start .vms_DateRangeCalendar_SelectedDate:before,
.main-check-in :global .vms_DateRangeCalendar_dateTableContainer_row .selected.start .vms_DateRangeCalendar_SelectedDate:before {
    content: "";
    position: absolute;
    top: 0;
    height: 30px;
    right: -10px;
    width: 33px;
    background-color: #ecf4fd;
    z-index: -1;
}

.calender-container :global .vms_DateRangeCalendar_itemMainContainer>div,
.main-check-in :global .vms_DateRangeCalendar_itemMainContainer>div {
    position: relative;
    height: 30px;
    margin-bottom: 10px;
}

.calender-container :global .vms_DateRangeCalendar_SelectedDateImage_Container img,
.main-check-in :global .vms_DateRangeCalendar_SelectedDateImage_Container img {
    display: none;

}

.calender-container :global td.selected.between.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_dateItem,
.main-check-in :global td.selected.between.vms_DateRangeCalendar_itemMainContainer .vms_DateRangeCalendar_dateItem {
    position: absolute;
    background: none;
    width: auto;
    height: auto;
}

.calender-container :global td.vms_DateRangeCalendar_todayDate_Active .vms_DateRangeCalendar_dateItem,
.main-check-in :global td.vms_DateRangeCalendar_todayDate_Active .vms_DateRangeCalendar_dateItem {
    color: #027bff;
    border: 1px solid #027BFF;
    border-radius: 50%;
    background-color: #fff;
    width: 30px !important;
    height: 30px !important;
    border-radius: 100%;
    line-height: 30px !important;
}

.calender-container :global td.vms_DateRangeCalendar_itemMainContainer:hover .vms_DateRangeCalendar_dateItem,
.main-check-in :global td.vms_DateRangeCalendar_itemMainContainer:hover .vms_DateRangeCalendar_dateItem {
    border-radius: 50%;
    background: #f4f4f8 !important;
    color: #027bff;
    border: none;
    width: 30px;
    height: 30px;
    line-height: 30px !important;
}